import { afterNextRender, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { AuthService } from './services/auth/auth.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    ButtonModule,
    RouterOutlet,
    ToastModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'Germinar';
  private agentComponent: any;

  constructor(
    private readonly authService: AuthService,
  ){
    afterNextRender(() => {
      this.loadAgent();
    });
  }

  private loadAgent(): void {
    this.agentComponent = document.querySelector('df-messenger');
    this.authService.currentUser().subscribe({
      next: (user) => {
        if (user) {
          document.body.appendChild(this.agentComponent);
          return;
        }
        document.body.removeChild(this.agentComponent);
      }
    });
  }
}
